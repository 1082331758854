<template>
  <div>
    <div class="contents magazine-view">
      <template v-if="detailData && routerList">
        <router-link
          v-if="detailData.abridgement.preAbridgement"
          :to="{ path: `/magazine/${detailData.abridgement.preAbridgement.id}`, query: { list: routerList - 1 }}"
          type="button"
          class="btn-prev-top"
        >
          <div class="num">
            <!-- {{ detailData.abridgement.preAbridgement.id }} -->
            {{ routerList - 1 }}
          </div>
          <div class="text">{{ detailData.abridgement.preAbridgement.magazineTitle }}</div>
        </router-link>
        <div>
          <div class="key-visual">
            <div class="num">
              <!-- {{ detailData.id }} -->
              {{ $route.query.list }}
            </div>
            <div
              class="bg"
              :style="{
                backgroundImage: `url(${imgUrl(detailData.pcDetailKeyPicturePhysicalName)})`
              }"
            ></div>
          </div>
          <div class="page-tit">
            <h2 class="tit">
              {{ detailData.magazineTitle }}
            </h2>
            <div class="info-area">
              <span class="date">{{ detailData.reportingDate | dateFormat }}</span>
              <ul class="share">
                <!--<li>
                  <ShareNetwork
                    network="facebook"
                    :url="facebookUrl"
                    title="이모션글로벌 | MAKE GREATNESS emotion "
                    description="UX컨설팅/소프트웨어 자문/개발 및 공급/어플리케이션 제작/웹, 앱디자인 등 디지털 전반의 모든 서비스를 제공하는 Full service Digital Agency입니다."
                    quote="UX컨설팅/소프트웨어 자문/개발 및 공급/어플리케이션 제작/웹, 앱디자인 등 디지털 전반의 모든 서비스를 제공하는 Full service Digital Agency입니다."
                  >
                    <img :src="require('@/assets/images/share-facebook.gif')" alt="페이스북 공유" />
                  </ShareNetwork>
                </li>-->
                <li>
                  <button type="button" @click="facebookShare">
                    <img :src="require('@/assets/images/share-facebook.gif')" alt="페이스북 공유" />
                  </button>
                </li>
                <li>
                  <button type="button" @click="print">
                    <img :src="require('@/assets/images/share-print.gif')" alt="프린트" />
                  </button>
                </li>
                <li>
                  <button type="button" @click="copyUrl">
                    <img :src="require('@/assets/images/share-url.gif')" alt="url 공유" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="page-cont">
            <div
              ref="getImg"
              class="page-cont-inner"
              v-html="handleNewLine(detailData.magazineContents)"
            ></div>
            <div class="writer">
              <div class="picture">
                <img :src="imgUrl(detailData.pcAuthorImagePhysicalName)" alt="" />
              </div>
              <div class="info">
                <div class="personal">
                  <span>{{ detailData.authorName }}</span>
                  <span>{{ detailData.authorJobRank }}</span>
                </div>
                <div class="department">
                  <span>{{ detailData.authorDepartment }}</span>
                  <span>e&middot;motion</span>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-num">
            {{ detailData.id }}
          </div>
        </div>
        <router-link
          v-if="detailData.abridgement.preAbridgement"
          :to="{ path: `/magazine/${detailData.abridgement.preAbridgement.id}`, query: { list: routerList - 1 }}"
          class="btn-prev-bottom"
        >
          <div class="num">
            <!-- {{ detailData.abridgement.preAbridgement.id }} -->
            {{ routerList - 1 }}
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>
<script>
  import {apiMagazineDetail} from "@api/index";
  import {commonMethods} from "@/utils/common-methods";

  export default {
    name: "item",
    metaInfo() {
      return {
        routerList: 1,
        title: "Magazine",
        titleTemplate: "%s | e · motion",
        meta: [
          {
            property: "og:title",
            content: `${this.detailData?.magazineTitle} | e · motion`,
            vmid: "og:title"
          },
          {
            property: "og:url",
            content: `${process.env.VUE_APP_URL}/magazine/${this.detailData?.id}/`,
            vmid: "og:url"
          },
          {
            property: "og:image",
            content: `${process.env.VUE_APP_API_URL}${this.detailData?.pcKeyPicturePhysicalName}`,
            vmid: "og:image"
          },
          {
            property: "og:type",
            content: "website",
            vmid: "og:type"
          },
          {
            property: "og:site_name",
            content: "이모션글로벌",
            vmid: "og:site_name"
          },
          {
            property: "og:description",
            content:
              "UX컨설팅/소프트웨어 자문/개발 및 공급/어플리케이션 제작/웹, 앱디자인 등 디지털 전반의 모든 서비스를 제공하는 Full service Digital Agency입니다.",
            vmid: "og:description"
          }
        ]
      };
    },
    data() {
      return {
        scene: null,
        id: this.$route.params.id,
        detailData: null,
        facebookUrl: `http://www.facebook.com/share.php?u=${window.location.href}`
      };
    },
    mixins: [commonMethods],
    methods: {
      facebookShare() {
        window.open(`http://www.facebook.com/share.php?u=${window.location.href}`);
      },
      print() {
        window.print();
      },
      copyUrl() {
        const t = document.createElement("textarea");
        document.body.appendChild(t);
        t.value = window.location.href;
        t.select();
        document.execCommand("copy");
        document.body.removeChild(t);
        alert("URL이 클립보드에 복사되었습니다.");
      },
      async fetchData() {
        try {
          const {data: response} = await apiMagazineDetail(this.$route.params.id);
          if (response.code === "NOT_FOUND") {
            alert(response.message);
            this.$router.push("/magazine/");
          }
          console.log(response.data);
          this.detailData = response.data;
        } catch (error) {
          console.error(error);
        }
      }
    },
    created() {
      this.fetchData();
      this.routerList = parseInt(this.$route.query.list);
    }
  };
</script>
<style lang="scss" scoped>
  .magazine-view {
    position: relative;
    min-height: 100vh;
    [class^="btn-prev"] {
      position: absolute;
      top: 214px;
      left: calc(100% + 100px);
      width: 170px;
      .num {
        position: relative;
        display: inline-flex;
        padding-right: 38px;
        @include montserrat;
        font-weight: 700;
        font-size: 48px;
        color: #b7b7b7;
        transition: all 0.3s ease-in-out;
        &::before {
          display: block;
          content: "";
          position: absolute;
          bottom: -3px;
          right: 0;
          left: 0;
          height: 1px;
          background: #b7b7b7;
          transition: all 0.3s ease-in-out;
        }
        &::after {
          display: block;
          content: "";
          position: absolute;
          right: 0;
          bottom: -3px;
          z-index: -1;
          width: 9px;
          height: 9px;
          background: url(../../assets/images/arrow_right_gray.png) no-repeat;
          transition: all 0.3s ease-in-out;
        }
      }
      .text {
        margin-top: 171px;
        font-size: 14px;
        color: #b7b7b7;
        line-height: 24px;
        word-break: break-word;
      }
    }
    .btn-prev-bottom {
      top: auto;
      bottom: 113px;
    }
  }

  .key-visual {
    padding: 214px 0 0;
    .num {
      @include montserrat;
      font-weight: 700;
      font-size: 48px;
      color: #000;
    }
    .bg {
      width: 100%;
      height: 362px;
      margin: 24px 0 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .page-tit {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 68px 0 0;
    word-break: keep-all;
    .tit {
      padding: 0 50px 0 0;
      font-weight: 700;
      font-size: 44px;
      line-height: 68px;
      letter-spacing: -0.05em;
      color: #000;
    }
    .info-area {
      text-align: right;
      flex: 0 0 auto;
      .date {
        @include montserrat;
        display: block;
        margin: 0 30px 0 0;
        font-size: 14px;
        line-height: 68px;
        color: #b7b7b7;
      }
      .share {
        display: flex;
        margin: 25px 24px 0 0;
        li {
          button {
            display: block;
            width: 28px;
            height: 28px;
            padding: 0;
            background: none;
          }
          & + li {
            margin: 0 0 0 12px;
          }
        }
      }
    }
  }

  .page-cont {
    position: relative;
    margin: 65px 0 0 335px;
    padding: 37px 0 275px;
    line-height: 1.6;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #ee2c3c;
    }
    &-inner {
      ::v-deep h2,
      ::v-deep h3,
      ::v-deep h4,
      ::v-deep h5,
      ::v-deep h6 {
        margin-top: 10px;
        font-weight: 700 !important;
        line-height: 1.8;
        letter-spacing: -0.05em;
        color: #333;
        &:first-child {
          margin-top: 0;
        }
        & + p {
          margin-top: 10px;
        }
      }
      ::v-deep h2 {
        font-size: 30px !important;
      }
      ::v-deep h3 {
        font-size: 26px !important;
      }
      ::v-deep h4 {
        font-size: 22px !important;
      }
      ::v-deep h5 {
        font-size: 18px !important;
      }
      ::v-deep h6 {
        font-size: 18px !important;
      }
      ::v-deep p {
        overflow: hidden;
      }
      ::v-deep p,
      ::v-deep li {
        font-size: 18px;
        letter-spacing: -0.05em;
        color: #333;
        word-break: keep-all;
      }
      ::v-deep img {
        max-width: 100% !important;
        height: auto !important;
      }
      ::v-deep table {
        width: 100% !important;
      }
      ::v-deep strong {
        font-weight: bold;
      }
      ::v-deep em {
        font-style: italic;
      }
      ::v-deep ol {
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
      }
      ::v-deep ul {
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
      }
      /*::v-deep span {
        display: block;
        margin: 11px 0 0;
        font-size: 14px;
        line-height: 34px;
        letter-spacing: -0.05em;
        color: #888;
      }*/
      ::v-deep video,
      ::v-deep iframe {
        max-width: 100%;
      }
    }
    .group {
      word-break: keep-all;
      & + .group {
        margin: 65px 0 0;
      }
    }
    .tit {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #333;
    }
    .desc {
      margin: 26px 0 0;
      font-size: 18px;
      line-height: 34px;
      color: #333;
    }
    .img {
      margin: 30px 0 0;
      .img-sub {
        display: block;
        margin: 11px 0 0;
        font-size: 14px;
        line-height: 34px;
        color: #888;
      }
    }
    .writer {
      display: flex;
      margin: 110px 0 0;
      .picture {
        overflow: hidden;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        img {
          vertical-align: top;
        }
      }
      .info {
        margin: 0 0 0 30px;
        .personal {
          display: flex;
          span {
            font-weight: 700;
            font-size: 14px;
            line-height: 34px;
            color: #000;
            & + span {
              margin: 0 0 0 25px;
            }
            &:first-child {
              letter-spacing: -0.05em;
            }
          }
        }
        .department {
          display: flex;
          span {
            @include montserrat;
            position: relative;
            font-size: 14px;
            line-height: 30px;
            color: #888;
            & + span {
              margin: 0 0 0 12px;
              padding: 0 0 0 13px;
              &::before {
                display: block;
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 1px;
                height: 10px;
                background: #b7b7b7;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
  .page-cont-inner {
    min-height: 100vh;
  }
  .bottom-num {
    position: absolute;
    bottom: 113px;
    left: 0;
    @include montserrat;
    font-weight: 700;
    font-size: 48px;
    color: #000;
  }
</style>
